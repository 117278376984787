import $ from "jquery";

import("swiper/scss");
import("swiper/scss/autoplay");
import("swiper/scss/effect-fade");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Autoplay, Pagination, EffectFade } = await import("swiper/modules");
  $(".slider--type-1 .swiper").each(function () {
    new Swiper(this, {
      modules: [Autoplay, Pagination, EffectFade],
      autoplay: true,
      speed: 1000,
      spaceBetween: 0,
      effect: "fade",
      slidesPerView: 1,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: this.querySelector(".pagination"),
        clickable: true,
      },
    });
  });
})();
